@import "../../global.scss";

.footer{
    background-color: rgb(21, 22, 23);
    color: $primary-light!important;
}

.footer__heading{
    color: $primary-light!important;
    padding-bottom: 20px;
}

.footer__item{
    cursor: pointer;
    padding: 5px 0px 5px 0px;
    color: $primary-light!important;
}

.footer__item:hover{
    text-decoration: underline;
}